// App.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';



function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
            // Static authentication check
            if (username === process.env.REACT_APP_USERNAME && 
                password === process.env.REACT_APP_PASSWORD) {
                setIsAuthenticated(true);
                setLoginError('');
                // Store authentication in localStorage
                localStorage.setItem('isAuthenticated', 'true');
            } else {
                throw new Error('Invalid credentials');
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoginError('Invalid username or password');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        
        try {
            const contactsFile = formData.get('contacts');
            if (!contactsFile) {
                throw new Error('Please select a contacts file');
            }
    
            const results = {
                success: 0,
                fails: 0,
                details: {
                    sent: [],
                    failed: []
                }
            };
    
            // Read the file
            const fileContent = await readFile(contactsFile);
            const contacts = await parseContacts(contactsFile.name, fileContent);
            
            // Get message template
            let messageTemplate = formData.get('message') || '';
            if (formData.get('messageFile')) {
                const messageFileContent = await readFile(formData.get('messageFile'));
                messageTemplate = messageFileContent;
            }
    
            if (!messageTemplate) {
                throw new Error('Please provide a message');
            }
    
            // Process each contact
            for (const contact of contacts) {
                try {
                    // Replace placeholders in message
                    const finalMessage = messageTemplate
                        .replace(/{name}/g, contact.name || '')
                        .replace(/{company}/g, contact.company || '');
    
                    // Add URL if provided
                    const urlValue = formData.get('url');
                    const fullMessage = urlValue ? `${finalMessage}\n\n${urlValue}` : finalMessage;
    
                    // In a real application, you would send the message here
                    // For now, we'll simulate success
                    results.success++;
                    results.details.sent.push({
                        phone: contact.phone,
                        name: contact.name,
                        message: fullMessage
                    });
                } catch (error) {
                    results.fails++;
                    results.details.failed.push({
                        phone: contact.phone,
                        name: contact.name,
                        error: error.message
                    });
                }
            }
    
            setResults(results);
            setError(null);
    
        } catch (error) {
            setError({ error: 'Failed to process messages', details: error.message });
            setResults(null);
        }
    };
    
    const handleLogout = () => {
        setIsAuthenticated(false);
        setUsername('');
        setPassword('');
        localStorage.removeItem('isAuthenticated');
    };

    // Helper function to read file contents
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsText(file);
        });
    };
    
    // Helper function to parse contacts from file
    const parseContacts = async (fileName, content) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        const contacts = [];
    
        if (fileExtension === 'csv') {
            // Parse CSV
            const rows = content.split('\n');
            const headers = rows[0].toLowerCase().split(',');
            
            const phoneIndex = headers.findIndex(h => 
                h.includes('phone') || h.includes('contact') || h.includes('number'));
            const nameIndex = headers.findIndex(h => h.includes('name'));
            const companyIndex = headers.findIndex(h => 
                h.includes('company') || h.includes('organization'));
    
            if (phoneIndex === -1) {
                throw new Error('No phone number column found in CSV');
            }
    
            for (let i = 1; i < rows.length; i++) {
                const values = rows[i].split(',');
                if (values.length > phoneIndex) {
                    const phone = values[phoneIndex].trim().replace(/[\s\-\(\)]/g, '');
                    if (phone) {
                        contacts.push({
                            phone: phone.startsWith('+') ? phone : '+' + phone,
                            name: nameIndex !== -1 ? values[nameIndex].trim() : '',
                            company: companyIndex !== -1 ? values[companyIndex].trim() : ''
                        });
                    }
                }
            }
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            // For Excel files, you'll need to add the xlsx library
            try {
                const XLSX = await import('xlsx');
                const workbook = XLSX.read(content, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
    
                data.forEach(row => {
                    const phone = row.phone || row.Phone || row.PHONE || 
                                row['Phone Number'] || row.number || row.Number;
                    
                    if (phone) {
                        const phoneStr = phone.toString().trim().replace(/[\s\-\(\)]/g, '');
                        contacts.push({
                            phone: phoneStr.startsWith('+') ? phoneStr : '+' + phoneStr,
                            name: (row.name || row.Name || row.NAME || '').toString().trim(),
                            company: (row.company || row.Company || row.COMPANY || '').toString().trim()
                        });
                    }
                });
            } catch (error) {
                throw new Error(`Error parsing Excel file: ${error.message}`);
            }
        } else {
            throw new Error('Unsupported file format');
        }
    
        if (contacts.length === 0) {
            throw new Error('No valid contacts found in the file');
        }
    
        return contacts;
    };

    // Check for authentication on component mount
    React.useEffect(() => {
        const isAuth = localStorage.getItem('isAuthenticated');
        if (isAuth === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    if (!isAuthenticated) {
        return (
            <div className="login-container">
                <div className="logo">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
                        alt="WhatsApp Logo"
                        style={{ width: '60px', height: 'auto' }}
                    />
                    <h2>WhatsApp Bulk Messenger</h2>
                </div>
                <form className="login-form" onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        disabled={isLoading}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={isLoading}
                    />
                    {loginError && <div className="error-message">{loginError}</div>}
                    <button type="submit" disabled={isLoading}>
                        {isLoading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="header">
                <h1>WhatsApp Bulk Messenger</h1>
                <button className="logout-btn" onClick={handleLogout}>Logout</button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="contacts">Contacts File (CSV/Excel):</label>
                    <input
                        type="file"
                        id="contacts"
                        name="contacts"
                        accept=".csv,.xlsx,.xls"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        placeholder="Use {name} and {company} for personalization"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <p>Example:</p>
                    <p>Hi {'{name}'}</p>
                    <p>Wishing you and your loved ones a joyous Christmas filled with love, laughter, and happiness.</p>
                    <p>Warm regards,</p>
                    <p>{'{company}'} Team</p>
                </div>

                <div className="form-group">
                    <label htmlFor="messageFile">Or Upload Message File:</label>
                    <input
                        type="file"
                        id="messageFile"
                        name="messageFile"
                        accept=".txt"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="url">URL (Optional):</label>
                    <input
                        type="text"
                        id="url"
                        name="url"
                        placeholder="https://example.com"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="attachment">Attachment (Optional):</label>
                    <input
                        type="file"
                        id="attachment"
                        name="attachment"
                    />
                </div>

                <button type="submit">Send Messages</button>
            </form>

            {results && (
                <div className="status success">
                    <p>Messages sent successfully!</p>
                    <p>Success: {results.success}</p>
                    <p>Failed: {results.fails}</p>
                    <div>
                        <h3>Detailed Results:</h3>
                        <pre>{JSON.stringify(results.details, null, 2)}</pre>
                    </div>
                </div>
            )}

            {error && (
                <div className="status error">
                    <p>Error: {error.error}</p>
                    <p>Details: {error.details}</p>
                </div>
            )}
        </div>
    );
}

export default App;